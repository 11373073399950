// If you don't want to use TypeScript you can delete this file!
import * as React from "react";
import { PageProps } from "gatsby";

import {
  CommentText,
  Content,
  Jumbotron,
  Layout,
  Section,
  SEO,
  Row,
  Col,
  OfficeMap,
  ContactForm,
} from "../ui";

type DataProps = {
  site: {
    buildTime: string;
  };
};

const ContactUs: React.FC<PageProps<DataProps>> = ({ location }) => (
  <Layout location={location} title="Contact Us">
    <SEO title="Contact Us" />
    <Jumbotron>
      <h1>Contact Us</h1>
      <CommentText>
        Interested in working with codemix? Send us a message:
      </CommentText>
      <hr />
      <ContactForm />
    </Jumbotron>
    <Content>
      <Section
        title={
          <span>
            Call us on <a href="tel:+441347833119">(+44) 01347 833 119</a>
          </span>
        }
      />
      <Section
        title={
          <span>
            Email us at <a href="mailto:hello@codemix.com">hello@codemix.com</a>
          </span>
        }
      />

      <Section title="Find us">
        <Row>
          <Col flex={3}>
            <OfficeMap />
          </Col>
          <Col>
            <address>
              <strong>codemix ltd.</strong>
              <br />
              The Annex
              <br />
              Alne Lodge
              <br />
              Alne,
              <br />
              York
              <br />
              YO61 1TT
              <br />
              <br />
              United Kingdom
            </address>
          </Col>
        </Row>
      </Section>
    </Content>
  </Layout>
);

export default ContactUs;
